import { createApi, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react"

import { AuthResponse, TTFError, TTFErrorArray } from "../types/types"
import { ENV } from "../utils/env"
import { deleteCookieByName, getCookieByName, setCustomCookie } from "../utils/session"

export const processErrorResponse = (defaultMessage: string, response: FetchBaseQueryError) => {
  const data = response.data as TTFErrorArray | TTFError
  if ("errors" in data) {
    return data.errors[0].details
  }
  if ("error" in data) {
    return data.message
  }
  return defaultMessage
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV.API_CONSUMER || "https://www.test.com"}/api`,
    credentials: "include",
    headers: {
      "Accept": "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
    },
  }),
  tagTypes: ["Auth"],
  endpoints: (build) => ({
    getSession: build.query<AuthResponse, void>({
      query: () => ({
        url: "/auth",
      }),
      providesTags: () => [{ type: "Auth" }],
      transformResponse: (response: AuthResponse, meta): AuthResponse => {
        const xtfcommerce = meta?.response?.headers.get("X-TF-ECOMMERCE")
        if (xtfcommerce) {
          setCustomCookie('X-TF-ECOMMERCE', xtfcommerce)
        }
        return response
      },
      transformErrorResponse: (response) => {
        deleteCookieByName('X-TF-ECOMMERCE')
        return response
      },
    }),
    logIn: build.mutation<
      AuthResponse,
      {
        email: string
        password: string
      }
    >({
      transformResponse: (response: AuthResponse, meta): AuthResponse => {
        const xtfcommerce = meta?.response?.headers.get("X-TF-ECOMMERCE")
        if (xtfcommerce) {
          setCustomCookie('X-TF-ECOMMERCE', xtfcommerce)
        }
        return response
      },
      transformErrorResponse: (response) => {
        deleteCookieByName('X-TF-ECOMMERCE')
        return processErrorResponse("Error when signing in!", response)
      },
      query: ({ email, password }) => ({
        url: `/auth`,
        method: "POST",
        body: {
          email,
          password,
        },
      }),
    }),
    logOut: build.mutation<unknown, void>({
      transformResponse: (response): unknown => {
        deleteCookieByName('X-TF-ECOMMERCE')
        return response
      },
      transformErrorResponse: (response) => processErrorResponse("Error when signing out!", response),
      query: () => ({
        url: `/auth`,
        method: "DELETE",
      }),
    }),
  }),
})

export const passwordRestoreApi = createApi({
  reducerPath: "passwordRestoreApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV.BUSINESS_CONSUMER || "https://www.test.com"}/api`,
    credentials: "include",
    headers: {
      "Accept": "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  }),
  tagTypes: ["PasswordRestore"],
  endpoints: (build) => ({
    passwordRestore: build.mutation<
      void,
      {
        email: string
      }
    >({
      transformErrorResponse: (response) => processErrorResponse("Error when restoring password!", response),
      query: ({ email }) => ({
        url: `/password-restore`,
        method: "POST",
        body: {
          data: {
            type: "password_reset",
            attributes: {
              email,
            },
          },
        },
      }),
    }),
  }),
})

export const {
  useLogInMutation,
  useLogOutMutation,
  useGetSessionQuery,
} = authApi

export const {
  usePasswordRestoreMutation
} = passwordRestoreApi
