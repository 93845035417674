import { FC, Suspense, useState } from "react"
import { Await, Outlet, useLoaderData } from "react-router"

import { useGetSessionQuery, useLogOutMutation } from "../../api/auth"
import { ReactComponent as HomeSVG } from "../../assets/home.svg"
import { ReactComponent as UserSVG } from "../../assets/user.svg"
import { Button } from "../../components/Button"
import { customButtonContainer } from "../../components/Button/Button.css"
import { Header } from "../../components/Header"
import { HeaderLink } from "../../components/HeaderLink"
import { SideBarLink } from "../../components/SideBarLink"
import { DataLoaderHeader } from "../../types/types"
import { catchError } from "../Home"
import { baseHomeContainer } from "../Home/Home.css"
import {
  baseHeaderContainer,
  baseInfoBox,
  customSideBarContainer,
  customSideBarText,
} from "./Header.css"

export const HeaderAuth: FC = () => {
  const data = useLoaderData() as DataLoaderHeader
  const { data: auth } = useGetSessionQuery()
  const [logOut, { isLoading }] = useLogOutMutation()
  const [showSideBar, setSideBar] = useState(true)
  return (
    <div className={baseHeaderContainer}>
      <Suspense fallback={<Header>{null}</Header>}>
        <Await
          resolve={data.auth}
          errorElement={
            <Header>
              <HeaderLink to="/login">Log In</HeaderLink>
            </Header>
          }
        >
          {() => (
            <Header>
              {
                <Button
                  disabled={isLoading}
                  onClick={async () => {
                    try {
                      await logOut().unwrap()
                      window.location.href = "/"
                    } catch (e) {
                      catchError(e)
                    }
                  }}
                  className={customButtonContainer.containerHeaderLink}
                  isLoading={isLoading}
                  size="small"
                  color="white"
                >
                  Log Out
                </Button>
              }
            </Header>
          )}
        </Await>
      </Suspense>
      <Suspense
        fallback={
          <div className={baseHomeContainer}>
            <Outlet />
          </div>
        }
      >
        <Await
          resolve={data.auth}
          errorElement={
            <div className={baseHomeContainer}>
              <Outlet />
            </div>
          }
        >
          {() => (
            <div className={baseHomeContainer}>
              <div
                className={
                  showSideBar
                    ? customSideBarContainer.open
                    : customSideBarContainer.closed
                }
              >
                <div>
                  <SideBarLink
                    title="Home Page Events"
                    setSideBar={setSideBar}
                    showSideBar={showSideBar}
                    to="/"
                    icon={(props) => <HomeSVG {...props} />}
                  />
                  <SideBarLink
                    title="Completed Events"
                    setSideBar={setSideBar}
                    showSideBar={showSideBar}
                    to="/completed-events"
                    icon={(props) => <UserSVG {...props} />}
                  />
                  <SideBarLink
                    title="Brands"
                    setSideBar={setSideBar}
                    showSideBar={showSideBar}
                    to="/brands"
                    icon={(props) => <UserSVG {...props} />}
                  />
                  <SideBarLink
                    title="Event Alternative Views"
                    setSideBar={setSideBar}
                    showSideBar={showSideBar}
                    to="/event-alternative-views"
                    icon={(props) => <UserSVG {...props} />}
                  />
                  <SideBarLink
                    title="ID Verification Status"
                    setSideBar={setSideBar}
                    showSideBar={showSideBar}
                    to="/id-verification-status"
                    icon={(props) => <UserSVG {...props} />}
                  />
                  <SideBarLink
                    title="Co-Branding/Partnership"
                    setSideBar={setSideBar}
                    showSideBar={showSideBar}
                    to="/co-brands"
                    icon={(props) => <UserSVG {...props} />}
                  />
                  <SideBarLink
                    title="Ticket Swapper"
                    setSideBar={setSideBar}
                    showSideBar={showSideBar}
                    to="/ticket-swapper"
                    icon={(props) => <UserSVG {...props} />}
                  />
                  <SideBarLink
                    title="Disputes / Chargebacks"
                    setSideBar={setSideBar}
                    showSideBar={showSideBar}
                    to="/disputes"
                    icon={(props) => <UserSVG {...props} />}
                  />
                  <SideBarLink
                    title="Incoming Enquiries"
                    setSideBar={setSideBar}
                    showSideBar={showSideBar}
                    to="/incoming-enquiries"
                    icon={(props) => <UserSVG {...props} />}
                  />
                  <SideBarLink
                    title="Session Management"
                    setSideBar={setSideBar}
                    showSideBar={showSideBar}
                    to="/session-management"
                    icon={(props) => <UserSVG {...props} />}
                  />
                  <SideBarLink
                    title="Brand Verification"
                    setSideBar={setSideBar}
                    showSideBar={showSideBar}
                    to="/brand-verification"
                    icon={(props) => <UserSVG {...props} />}
                  />
                </div>
                {showSideBar ? (
                  <div className={baseInfoBox}>
                    <div className={customSideBarText.name}>
                      {auth?.data.firstName +
                        " " +
                        auth?.data.lastName}
                    </div>
                    <div className={customSideBarText.email}>
                      {auth?.data.email}
                    </div>
                  </div>
                ) : null}
              </div>
              <Outlet />
            </div>
          )}
        </Await>
      </Suspense>
    </div>
  )
}
