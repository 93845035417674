import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import {
  CustomerResponse, ResetVerificationRequest,
} from "../types/types"
import { ENV } from "../utils/env"
import { processErrorResponse } from "./auth"

export const customersApi = createApi({
  reducerPath: "customersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV.BUSINESS_CONSUMER}/api`,
    credentials: "include",
    headers: {
      "Accept": "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  }),
  tagTypes: ["Customers"],
  endpoints: (build) => ({
    getVerificationStatus: build.query<
      CustomerResponse,
      { search_query: string; page: number; limit: number }
    >({
      query: ({ search_query, page, limit }) => ({
        url:
          "/id-verification-status?" +
          new URLSearchParams({
            search_query,
            page: String(page),
            limit: String(limit),
          }),
      }),
      providesTags: () => [{ type: "Customers" }],
    }),
    resetVerification: build.mutation<
      ResetVerificationRequest,
      { search_query: string; page: number; limit: number; customer_id: string }
    >({
      transformErrorResponse: (response) => processErrorResponse("Error when editing event!", response),
      query: ({ customer_id }) => ({
        method: "PATCH",
        url:
          `/id-verification-status/${customer_id}`,
      }),
      async onQueryStarted(
        {
          customer_id,
          search_query,
          page,
          limit,
        },
        { dispatch, queryFulfilled }
      ) {
        try {
          const response = await queryFulfilled
          dispatch(
            customersApi.util.updateQueryData(
              "getVerificationStatus",
              { search_query, page, limit },
              (draft) => {
                const index = draft.data.customers.findIndex(
                  (customer) => customer.id === customer_id
                )
                if (index !== -1) {
                  draft.data.customers[index] = response.data.data.customer
                }
              }
            )
          )
        } catch { }
      },
    }),
  }),
})

export const {
  useGetVerificationStatusQuery,
  useResetVerificationMutation
} = customersApi
