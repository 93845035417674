import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { Status } from "../components/CheckboxCell"
import {
  AddNoteResponse,
  CheckboxEditCompletedEventTypes,
  CheckboxEditEventTypes,
  CompletedEventsResponse,
  EventAlternativeViewResponse,
  InternalEventsResponse,
} from "../types/types"
import { ENV } from "../utils/env"
import { getCookieByName } from "../utils/session"
import { processErrorResponse } from "./auth"

export const internalEventsApi = createApi({
  reducerPath: "internalEventsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV.BUSINESS_CONSUMER}/api`,
    credentials: "include",
    headers: {
      "Accept": "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
      'X-TF-ECOMMERCE': getCookieByName('X-TF-ECOMMERCE'),
    },
  }),
  tagTypes: ["InternalEvents", "CompletedEvents", "EventAlternativeViews"],
  endpoints: (build) => ({
    getInternalEvents: build.query<
      InternalEventsResponse,
      { search_query: string; page: number; limit: number }
    >({
      query: ({ search_query, page, limit }) => ({
        url:
          "/internal-events?" +
          new URLSearchParams({
            search_query,
            page: String(page),
            limit: String(limit),
          }),
      }),
      providesTags: (_result, _error, { search_query, page, limit }) => [
        { type: "InternalEvents", search_query, page, limit },
      ],
    }),
    getCompletedEvents: build.query<
      CompletedEventsResponse,
      { search_query: string; page: number; limit: number }
    >({
      query: ({ search_query, page, limit }) => ({
        url:
          "/completed-events?" +
          new URLSearchParams({
            search_query,
            page: String(page),
            limit: String(limit),
          }),
      }),
      providesTags: (_result, _error, { search_query, page, limit }) => [
        { type: "CompletedEvents", search_query, page, limit },
      ],
    }),
    getEventAlternativeViews: build.query<EventAlternativeViewResponse, void>({
      query: () => ({
        url: "/event-alternative-views",
      }),
      providesTags: () => [{ type: "EventAlternativeViews" }],
    }),
    updateEventAlternativeViews: build.mutation<
      void,
      {
        id: string
        status: Status
      }
    >({
      transformErrorResponse: (response) => processErrorResponse("Error when editing alternative view!", response),
      query: ({ id, ...patch }) => ({
        url: `/event-alternative-view/${id}`,
        method: "PATCH",
        body: {
          data: {
            attributes: patch,
          },
        },
      }),
      async onQueryStarted({ id, status }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          internalEventsApi.util.updateQueryData(
            "getEventAlternativeViews",
            undefined,
            (draft) => {
              const index = draft.data.products.findIndex(
                (event) => event.view_id === id
              )
              if (index !== -1) {
                draft.data.products[index].show_on_home_page = !!(
                  status === "on"
                )
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    updateInternalEvent: build.mutation<
      void,
      {
        id: string
        status: Status
        property: CheckboxEditEventTypes
        search_query: string
        page: number
        limit: number
      }
    >({
      transformErrorResponse: (response) => processErrorResponse("Error when editing event!", response),
      query: ({ id, ...patch }) => ({
        url: `/internal-event/${id}`,
        method: "PATCH",
        body: {
          data: {
            attributes: patch,
          },
        },
      }),
      async onQueryStarted(
        { id, status, property, search_query, page, limit },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          internalEventsApi.util.updateQueryData(
            "getInternalEvents",
            { search_query, page, limit },
            (draft) => {
              const index = draft.data.products.findIndex(
                (event) => event.id === id
              )
              if (index !== -1) {
                draft.data.products[index][property] = !!(status === "on")
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    addNoteCompletedEvent: build.mutation<
      AddNoteResponse,
      {
        note: string
        eventId: string
        search_query: string
        page: number
        limit: number
      }
    >({
      transformErrorResponse: (response) => processErrorResponse("Error when creating note!", response),
      query: ({ note, eventId }) => ({
        url: `event/${eventId}/note`,
        method: "POST",
        body: {
          data: {
            attributes: {
              note,
              type: "completed_event",
            },
          },
        },
      }),
      async onQueryStarted(
        { eventId, search_query, page, limit },
        { dispatch, queryFulfilled }
      ) {
        const { data } = await queryFulfilled
        dispatch(
          internalEventsApi.util.updateQueryData(
            "getCompletedEvents",
            { search_query, page, limit },
            (draft) => {
              const index = draft.data.products.findIndex(
                (event) => event.id === eventId
              )
              if (index !== -1) {
                draft.data.products[index].note = data.data.attributes.note
                draft.data.products[index].note_id = String(
                  data.data.attributes.id
                )
                draft.data.products[index].note_created_at =
                  data.data.attributes.createdAt
                draft.data.products[index].note_updated_at =
                  data.data.attributes.updatedAt
                draft.data.products[index].first_name =
                  data.data.attributes.firstName
                draft.data.products[index].last_name =
                  data.data.attributes.lastName
              }
            }
          )
        )
      },
    }),
    editNoteCompletedEvent: build.mutation<
      AddNoteResponse,
      {
        note: string
        eventId: string
        search_query: string
        page: number
        limit: number
        noteId: string
      }
    >({
      transformErrorResponse: (response) => processErrorResponse("Error when editing note!", response),
      query: ({ note, eventId, noteId }) => ({
        url: `event/${eventId}/note/${noteId}`,
        method: "PUT",
        body: {
          data: {
            attributes: {
              note,
            },
          },
        },
      }),
      async onQueryStarted(
        { eventId, note, search_query, page, limit },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          internalEventsApi.util.updateQueryData(
            "getCompletedEvents",
            { search_query, page, limit },
            (draft) => {
              const index = draft.data.products.findIndex(
                (event) => event.id === eventId
              )
              if (index !== -1) {
                draft.data.products[index].note = note
              }
            }
          )
        )
        try {
          const { data } = await queryFulfilled
          dispatch(
            internalEventsApi.util.updateQueryData(
              "getCompletedEvents",
              { search_query, page, limit },
              (draft) => {
                const index = draft.data.products.findIndex(
                  (event) => event.id === eventId
                )
                if (index !== -1) {
                  draft.data.products[index].note = data.data.attributes.note
                  draft.data.products[index].note_updated_at =
                    data.data.attributes.updatedAt
                }
              }
            )
          )
        } catch {
          patchResult.undo()
        }
      },
    }),
    deleteNoteCompletedEvent: build.mutation<
      AddNoteResponse,
      {
        eventId: string
        search_query: string
        page: number
        limit: number
        noteId: string
      }
    >({
      transformErrorResponse: (response) => processErrorResponse("Error when deleting note!", response),
      query: ({ eventId, noteId }) => ({
        url: `event/${eventId}/note/${noteId}`,
        method: "DELETE",
      }),
      async onQueryStarted(
        { eventId, search_query, page, limit },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          internalEventsApi.util.updateQueryData(
            "getCompletedEvents",
            { search_query, page, limit },
            (draft) => {
              const index = draft.data.products.findIndex(
                (event) => event.id === eventId
              )
              if (index !== -1) {
                draft.data.products[index].note = null
                draft.data.products[index].note_id = null
                draft.data.products[index].note_created_at = null
                draft.data.products[index].note_updated_at = null
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch (err) {
          patchResult.undo()
        }
      },
    }),
    updateCompletedEvent: build.mutation<
      void,
      {
        id: string
        status: Status
        property: CheckboxEditCompletedEventTypes
        paid_in_advance_amount?: string
        search_query: string
        page: number
        limit: number
      }
    >({
      transformErrorResponse: (response) => processErrorResponse("Error when editing event!", response),
      query: ({ id, ...patch }) => ({
        url: `/internal-event/${id}`,
        method: "PATCH",
        body: {
          data: {
            attributes: patch,
          },
        },
      }),
      async onQueryStarted(
        {
          id,
          status,
          property,
          search_query,
          page,
          limit,
          paid_in_advance_amount,
        },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          internalEventsApi.util.updateQueryData(
            "getCompletedEvents",
            { search_query, page, limit },
            (draft) => {
              const index = draft.data.products.findIndex(
                (event) => event.id === id
              )
              if (index !== -1) {
                draft.data.products[index][property] = status === "on"
                if (property === "paid_in_advance" && status === "off") {
                  draft.data.products[index].paid_in_advance_amount = ""
                }
              }
            }
          )
        )
        try {
          await queryFulfilled
          if (property === "paid_in_advance" && paid_in_advance_amount) {
            dispatch(
              internalEventsApi.util.updateQueryData(
                "getCompletedEvents",
                { search_query, page, limit },
                (draft) => {
                  const index = draft.data.products.findIndex(
                    (event) => event.id === id
                  )
                  if (index !== -1) {
                    draft.data.products[index].paid_in_advance_amount =
                      status === "off" ? "" : paid_in_advance_amount
                  }
                }
              )
            )
          }
        } catch {
          patchResult.undo()
        }
      },
    }),
  }),
})

export const {
  useGetInternalEventsQuery,
  useUpdateInternalEventMutation,
  useGetCompletedEventsQuery,
  useUpdateCompletedEventMutation,
  useAddNoteCompletedEventMutation,
  useEditNoteCompletedEventMutation,
  useGetEventAlternativeViewsQuery,
  useUpdateEventAlternativeViewsMutation,
  useDeleteNoteCompletedEventMutation
} = internalEventsApi
